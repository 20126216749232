body {
    font-family: "Poppins", serif !important;

    font-style: normal;
}

.container {
    max-width: 1500px !important;
}


.section {
    margin: 60px 0 60px 0;
}

.slick-track {
    margin: 0 !important;
    display: flex !important;
    flex-wrap: nowrap !important;
    gap: 10px !important;
}

.section .title {
    padding-bottom: 40px;
    margin: 0px;
    text-align: center;
    font-family: "Satisfy", serif;
    font-weight: 500 !important;
    font-size: 37.5px;
    color: #000;
}

.page-banner .breadcrumb-title {
    text-align: center;
    font-family: "Satisfy", serif;
    font-weight: 500 !important;
    font-size: 37.5px;
    color: #fff;
}

@media (max-width:768px) {
    .section {
        margin: 40px 0 40px 0;
    }

    .section .title {
        padding-bottom: 30px;
        font-size: 30px;
    }
}

@media (max-width:425px) {
    .section {
        margin: 30px 0 30px 0;
    }

    .section .title {
        padding-bottom: 30px;
        font-size: 30px;
    }
}

/* Scroll To Top */

.scroll-top-btn {
    background-color: #b0cb1f;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    position: fixed;
    z-index: 10;
    bottom: 2rem;
    right: 2rem;
    border: none;
    transition: all ease-in-out 0.3s;
}

.scroll-top-btn:hover {
    background-color: #3b3b3b;
}

.scroll-top-btn svg {
    color: #fff;
}


/* loader css */

.rotate-ring-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* background-color: rgba(#fff, #fff, #fff, 0.1); */
}

.rotate-ring {
    position: relative;
    display: inline-block;
    padding: 10px;
}

.rotate-ring img {
    width: 30px;
    height: 30px;
}

.rotate-ring::before {
    content: "";
    position: absolute;
    top: -3px;
    left: -3px;
    right: -3px;
    bottom: -3px;
    border: 2px solid transparent;
    border-top-color: #d0ad6b;
    border-radius: 50%;
    animation: rotate-ring 1s linear infinite;
}

@keyframes rotate-ring {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}