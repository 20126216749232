.footer {
    background-color: #F0E8E6;
    padding: 40px 20px 20px 20px;
}

.footer .footer-logo {
    width: 230px;
    object-fit: cover;
}

.footer .footer-address {
    margin: 10px 0px;
    font-size: 14px;
    color: #3d3d3d;
}

.footer .footer-contact {
    margin: 10px 0px;
}

.footer .footer-social-media span {
    color: black;
    font-size: 15px;
    font-weight: 500;
}

.footer-social-media ul {
    padding-left: 0px;
    margin-top: 15px;
}

.footer-social-media ul li {
    list-style: none;
    display: inline-block;
    margin-right: 8px;
    padding-left: 0px;
    width: 30px;
    height: 30px;
    text-align: center;
}

.footer-social-media ul li a {
    text-decoration: none;
    color: #fff;
    font-size: 18px;
    display: block;
    line-height: 30px;
    background-color: #e82990;
    border-radius: 100%;
}

.footer .footer-contact a {
    font-size: 14px;
    color: #3d3d3d;
    text-decoration: none;
}

.footer-list {
    padding-left: 0px;
}

.footer-list li {
    list-style: none;
    margin-bottom: 10px;
}

.footer-title {
    font-weight: 500;
    margin-bottom: 40px;
}

.footer-list li a {
    text-decoration: none;
    color: #3d3d3d;
    font-size: 15px;
    font-weight: 400;
}

.footer-list li a:hover {
    color: #e82990;
}

.footer-copyright {
    text-align: center;
    font-size: 14px;
    color: #000;
    margin-bottom: 0px;
    padding: 10px 0px;
}

.footer-copyright a {
    text-decoration: none;
    color: #e82990;
    padding-left: 5px;
}

.footer-copyright a:hover {
    color: #e82990;
}

.icon-instagram2:before {
    content: "\e941";
}

.footer-hr{
    background-color: #bcbcbc;
}

/* Footer Mobile */
.footer-mobile {
    background-color: #F0E8E6;
    padding: 40px 20px 20px 20px;
}

.footer-mobile .footer-logo {
    width: 230px;
    object-fit: cover;
    margin-bottom: 30px;
}

.footer-mobile .footer-address {
    margin: 10px 0px;
    font-size: 14px;
    color: #3d3d3d;
}

.footer-mobile .footer-contact {
    margin: 10px 0px;
}

.footer-mobile .footer-contact a {
    font-size: 14px;
    color: #3d3d3d;
    text-decoration: none;
}

/* Accordion */
.accordion-header {
    border-bottom: 1px solid #000 !important;
}

.accordion-button {
    background-color: transparent !important;
    padding: 0.7rem 1.25rem 0.7rem 0rem !important;
}

.accordion-button:focus,
.accordion-button:focus-visible {
    outline: none !important;
    box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
    color: #000 !important;
    background-color: transparent !important
}

.accordion-body {
    padding: 0.7rem 0rem 0rem 0rem !important;
}

.accordion-body .accordion-button {
    background-color: transparent !important;
    padding: 0.7rem 1.25rem 0.7rem 0rem !important;
}

.accordion-button i {
    font-size: 15px !important;
}

.accordion-button span {
    font-size: 15px;
    font-weight: 500;
}

.rotate-icon {
    transform: rotate(180deg);
    transition: all ease-in-out 0.3s;
}

.reset-icon {
    transform: rotate(0deg);
    transition: all ease-in-out 0.3s;
}