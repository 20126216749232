/* Hide Desktop Navbar on Mobile/Tablet */
.navMenuDesktop {
    padding: 15px 30px;
    background-color: #fff;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    border-bottom: 1px solid #e0e0e0;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

.navMenuDesktop .navContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 100px;
}

.navMenuDesktop .logo img {
    display: block;
    height: auto;
    width: 190px;
}

.navMenuDesktop .menuItems ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 10px 0 0 0;
    gap: 20px;
}

.navMenuDesktop .menuItems ul li a {
    text-decoration: none;
    color: #000000;
    font-size: 15px;
    padding: 8px 0;
    transition: color 0.3s;
}

.navMenuDesktop .menuItems ul li a:hover {
    color: #e82990;
}

@media (max-width: 1024px) {
    .navMenuDesktop {
        display: none;
    }
}

/* Hide Mobile Navbar on Desktop */
.navMenuMobile {
    display: none;
}

@media (max-width: 1024px) {
    .navMenuMobile {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
        background-color: #e82990;
        position: sticky;
        top: 0;
        left: 0;
        right: 0;
        z-index: 100;
        border-bottom: 1px solid #e0e0e0;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .navMenuMobile .navContainer {
        display: flex;
        /* justify-content: space-between; */
        align-items: center;
    }

    .mobile-menu-div {
        text-align: center;
        background-color: #e9e9e9;
        padding: 15px 0px;
        border-bottom: solid 1px #e82990;
    }

    .mobile-menu-div span {
        color: #000;
        font-size: 18px;
        font-weight: 500;

    }

    .navMenuMobile .logo {
        margin: 0 auto;
    }

    .navMenuMobile .logo img {
        display: block;
        height: auto;
        width: 160px;
    }

    .hamburger {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 30px;
        height: 20px;
    }

    .hamburger .lines {
        background-color: #ffffff;
        height: 3px;
        width: 100%;
        border-radius: 2px;
        display: block;
        transition: all 0.3s ease-in-out;
    }

    .navMenuMobile .menuItems {
        flex-direction: column;
        position: fixed;
        top: 0;
        left: -100%;
        width: 280px;
        height: 100vh;
        background-color: #fff;
        box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
        transition: left 0.4s ease;
        /* padding: 20px; */
        z-index: 101;
    }



    .navMenuMobile .menuItems.active {
        left: 0;
    }

    .navMenuMobile .menuItems ul {
        display: flex;
        flex-direction: column;
        list-style: none;
        margin: 0;
        padding: 10px;
        /* gap: 15px; */
    }

    .navMenuMobile .menuItems ul li a {
        text-decoration: none;
        color: #000000;
        font-size: 15px;
        font-weight: 400;
        transition: color 0.3s ease, padding-left 0.3s ease;
    }

    .navMenuMobile .menuItems ul li a:hover {
        color: #e82990;
        padding-left: 5px;
    }

    /* Overlay */
    .menu-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.4);
        z-index: 100;
    }
}


.magic-dis-txt {
    text-align: center;
    margin: 15px auto;
    animation: magic-text 3s infinite;
    transition: all ease-in-out 0.3s;
    font-size: 15px;
}

@keyframes magic-text {
    0% {
        color: black;
    }

    25% {
        color: #e82990;
    }

    50% {
        color: black;
    }

    75% {
        color: #e82990;
    }

    100% {
        color: black;
    }
}