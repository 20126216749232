/* Amenties */
.amenties-content {
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
}

.amenties-content .icon-row img:hover {
    transform: translateY(-10px);
}

.section .icon-row {
    text-align: center;
}

.section .icon-row div img {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
    transition: transform 0.3s ease-in-out;
}

.section .icon-row div p {
    font-size: 16px;
    font-weight: 400;
    color: #666666;
}

/* Image Cakes */


.title {
    font-size: 2rem;
    font-weight: bold;
    color: #333;
}

.image-cakes {
    max-width: 250px;
    position: relative;
    cursor: pointer;
    margin: 0px 10px;
}

.carousel-content {
    margin: 0px 10px;
}

.main-img {
    width: 100%;
    border-radius: 10px;
}

.img-cakes-text {
    margin-top: -15px;
}

.img-cakes-text p {
    font-weight: 500;
    color: black;
    font-size: 14px;
}

.cloud-container {
    position: absolute;
    top: 10px;
    left: 10px;
}

.cloud-container small {
    font-size: 10px;
    font-weight: 600;
    color: white;
    line-height: 12px;
    position: absolute;
    top: 8px;
    left: 10px;
}

.cloud-container .cloud-img {
    width: 80px;
    height: auto;
    clip-path: inset(0 50% 0 0);
    object-fit: cover;
}

.nonveg-img {
    width: 30px;
    height: 30px;
}





/* Gallery */

.react-multi-carousel-item {
    margin: 0px 15px;
}

.title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2rem;
}

.gallery-container {
    display: flex;
    /* flex-wrap: wrap; */
    /* gap: 15px; */
    padding: 0px 10px;
}

.gallery-item {
    position: relative;
    overflow: hidden;
    margin-bottom: 20px;
}

.img-fluid {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease-in-out;
}

.image-overlay {
    position: relative;
    overflow: hidden;
}

.gallery-item:hover .image-overlay .img-fluid {
    transform: rotate(5deg) scale(1.3);
    opacity: 1;
}

.overlay-text1 {
    position: absolute;
    bottom: 50%;
    left: 50%;
    color: #fff;
    padding: 5px 10px;
    font-size: 2.5rem;
    line-height: 40px;
    transform: translate(-50%, 50%);
    font-family: "Satisfy", serif;
    font-weight: 500 !important;
    /* Centering with translate */
}

.overlay-text {
    position: absolute;
    bottom: 20px;
    left: 10px;
    color: #fff;
    padding: 5px 10px;
    font-size: 2.5rem;
    font-family: "Satisfy", serif;
    font-weight: 500 !important;
}

@media (max-width: 768px) {
    .overlay-text {
        position: absolute;
        bottom: 2px;
        left: 0px;
        color: #fff;
        padding: 5px 10px;
        font-size: 1.5rem;
        font-family: "Satisfy", serif;
        font-weight: 500 !important;
        line-height: 41px;
    }
}

@media (max-width:425px) {
    .overlay-text {
        position: absolute;
        bottom: 20px;
        left: 10px;
        color: #fff;
        padding: 5px 10px;
        font-size: 2.5rem;
        font-family: "Satisfy", serif;
        font-weight: 500 !important;
    }
}


.wave-hover {
    display: block;
    position: relative;
    overflow: hidden;
}

.wave-hover img {
    border-radius: 10px;
}

.wave-hover:before,
.wave-hover:after {
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: all .9s ease 0s;
}

.wave-hover:hover:before {
    background: rgba(255, 255, 255, 0.5) none repeat scroll 0 0;
    bottom: 50%;
    top: 50%;
}

.wave-hover:hover:after {
    background: rgba(255, 255, 255, 0.5) none repeat scroll 0 0;
    left: 51%;
    right: 50%;
}
    
/* Flavour Cakes */
.all-cake-links-title {
    font-size: 13px;
    font-family: "Montserrat", serif;
    font-weight: 500;
}

.all-cake-links a {
    text-decoration: none;
    color: #000;
    font-size: 12px;
    font-family: "Montserrat", serif;
    font-weight: 500;
    letter-spacing: 1px;
    transition: color 0.3s ease-in-out;
}

.all-cake-links a:hover {
    color: #e82990 !important;
}

/* About Us */

.about-txt h2 {
    color: #e82990;
    margin-top: 15px;
}

.about-txt p {
    text-transform: uppercase;
    text-align: justify;
    font-size: 15px;
    color: #523025;
}

.buddy-section h2 {
    text-align: center;
    font-weight: 400;
    padding: 30px 0px 20px 0px;
    border-bottom: 2px dotted #b0cb1f;
    color: #e82990;
}

.buddy-section .buddies h3 {
    color: #e82990;
    font-weight: 400;
}

.buddy-section .buddies p {
    font-size: 15px;
    line-height: 33px;
}

/* Privacy policy & Term Condition */
.privacy-policy {
    margin: 20px 0px 40px 0px;
}

.privacy-policy h2 {
    color: #e82990;
}

.privacy-policy p {
    line-height: 33px;
    font-size: 15px;
    text-align: justify;
    color: #000;
}

/* Contact Us */

.contact-us h3 {
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    position: relative;
    margin-bottom: 30px
}

.contact-us h3:before {
    background-color: #E82990;
}

.contact-us h3:before {
    content: "";
    display: block;
    width: 100px;
    height: 2px;
    position: absolute;
    bottom: -1px;
    left: 0;
}

.contact-us .c-address,
.contact-us .c-contact,
.contact-us .c-email,
.contact-us .c-time {
    display: flex;
    gap: 20px;
    margin-bottom: 5px;
}

.c-address .icon-location,
.c-contact .icon-phone,
.c-email .icon-email,
.c-time .icon-time {
    color: #E82990;
}

.contact-us .c-address p,
.contact-us .c-time p {
    color: #666666;
}

.contact-us .c-contact p a,
.contact-us .c-email p a {
    text-decoration: none;
    color: #666666;
    padding-left: 5px;
    transition: color 0.3s ease-in-out;
}

.social-media .sm-ul {
    padding-left: 0px;
}

.social-media .sm-ul .sm-li {
    list-style: none;
    display: inline-block;
    padding-left: 0px;
    width: 30px;
    height: 30px;
    text-align: center;
    margin: 0 25px 0 0;
}

.social-media .sm-ul .sm-li a {
    color: #fff;
    width: 44px;
    height: 44px;
    text-align: center;
    line-height: 44px;
    font-size: 18px;
    background-color: #E82990;
    display: block;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border: 1px solid #ddd;
    text-decoration: none;
}