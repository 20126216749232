@media (max-width: 1024px) {
    .magic-dis-txt {
        display: none;
    }

}


/* Carousel */

.carousel-container:hover .custom-arrow {
    opacity: 1;
    pointer-events: auto;
}

.custom-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: #fff;
    color: #000;
    font-size: 20px;
    padding: 18px;
    cursor: pointer;
    z-index: 10;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all ease-in-out 0.3s;
    opacity: 0;
    pointer-events: none;
    outline: 2px dashed #ffffff;
    outline-offset: 5px;
}

.custom-arrow:hover {
    background-color: #e82990;
    color: white;
    outline-color: #e82990;
}

.custom-arrow.prev {
    left: 20px;
}

.custom-arrow.next {
    right: 20px;
}


/* Page banner */

.page-banner {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../assets/banner/breadcrumb.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 130px;
}

.breadcrumb-title {
    color: #fff;
    font-size: 3rem;
    font-weight: bold;
}